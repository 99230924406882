import setting from './content/setting.json'

const language =
  (window?.navigator?.languages && window?.navigator?.languages[0]) ||
  window?.navigator?.language

export const locale = language && language?.split('-')[0]

// NOTE: 多言語の対象言語は IF で渡してもらった言語とする。日本語のみの場合は ja のみが IF に含まれる。
const supportedLocales = setting.availableLanguages

export default defineI18nConfig(() => ({
  legacy: false,
  locale: supportedLocales.includes(locale) ? locale : 'ja',
}))
